import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'externa-link-renderer',
	template: `<a [href]="link" [target]="openInNewTab ? '_blank' : ''">{{ this.displayValue }}</a>`,
	styles: [
		`
			a:visited {
				color: #0000ee;
			}
		`,
	],
})
export class ExternalLinkCellRenderer implements AgRendererComponent {
	public displayValue: any = undefined;
	public link: any = undefined;
	public openInNewTab = false;

	constructor(private router: Router) {}

	agInit(params: ICellRendererParams): void {
		if (!!params.value) {
			this.displayValue = params.value.displayName;
			this.link = params.value.link;
			this.openInNewTab = params.value.openInNewTab;
		}
	}

	refresh(params: ICellRendererParams): boolean {
		this.displayValue = params.value.displayName;
		this.link = params.value.link;
		this.openInNewTab = params.value.link;
		return true;
	}

	navigate() {
		this.router.navigate([this.link]);
	}
}
