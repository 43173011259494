import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerInformation, CustomerdbService } from 'src/app/components/services/customer-db/services/customer-db.service';
import { UserService } from 'src/app/services/user.service';
import { IamRoleService } from '../../../components/iam/services/iam-role.service';

@Component({
	selector: 'app-assumable-role-wildcard-dialog',
	templateUrl: './assumable-role-wildcard-dialog.component.html',
	styleUrls: ['./assumable-role-wildcard-dialog.component.scss'],
})
export class AssumableRoleWildcardDialogComponent implements OnInit {
	public customers: CustomerInformation[] = [];
	public wildcardRoles: string[] = [];
	public suggestedRoles: string[] = [];
	public currentTenant: string | undefined;
	public roleType: string = 'role';

	constructor(
		private dialogRef: MatDialogRef<AssumableRoleWildcardDialogComponent>,
		public readonly userService: UserService,
		private readonly roleService: IamRoleService,
		private readonly customerdbService: CustomerdbService
	) {}

	ngOnInit() {
		this.userService.tenant.subscribe((data) => {
			this.currentTenant = data;
		});
		this.loadAssumableRoles();
	}

	public close() {
		this.dialogRef.close();
	}

	async loadAssumableRoles() {
		try {
			const crn = await this.userService.getCRN();
			this.wildcardRoles = (await this.roleService.getAssumableAndWildcardRoles(crn)).wildcardRoles;
			this.changeRoleType(this.roleType);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
	}

	async searchCustomers(customer: string) {
		try {
			this.customers = await this.customerdbService.searchCustomer(customer);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
	}

	public async assumeWildcardRole(tenant: string, roleType: string, roleName: string) {
		if (this.currentTenant != 'cancom') {
			roleType = 'role';
		}
		try {
			await this.userService.assumeRole(`crn:${tenant}::iam:${roleType}:${roleName}`);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
		this.close();
	}

	changeRoleType(roleType: string) {
		this.suggestedRoles = [];
		for (const role of this.wildcardRoles) {
			const splitRole = role.split(':');
			if (splitRole[0] == roleType) {
				this.suggestedRoles.push(splitRole.slice(-1).join(':'));
			}
		}
	}
}
