<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">
		<span>{{ name }}</span>
		@if (!data.createField) {
			<span> Editor</span>
		}
	</p>
	<div fxLayout="column">
		<mat-form-field>
			<mat-label>{{ label }}</mat-label>
			<input matInput [value]="value" #input />
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		<button mat-raised-button color="primary" (click)="update(input.value)">{{ buttonText }}</button>
		<button mat-raised-button (click)="cancel()">Cancel</button>
	</div>
</div>
