import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
	selector: 'checkbox-renderer',
	template: `
		<input
			type="checkbox"
			(click)="checkedHandler($event)"
			[checked]="params.value"
			[disabled]="params.colDef?.cellRendererParams?.disabled"
		/>
	`,
})
export class CheckboxCellRenderer implements AgRendererComponent {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	checkedHandler(event: any) {
		const checked = event.target.checked;
		const colId = this.params.column.colId;
		this.params.node.setDataValue(colId, checked);
	}

	refresh(): boolean {
		return true;
	}
}
