@if (root) {
	<form [formGroup]="editmode" class="mb1">
		<mat-button-toggle-group formControlName="mode">
			<mat-button-toggle [value]="'default'">Default Editor</mat-button-toggle>
			<mat-button-toggle [value]="'json'">JSON Editor</mat-button-toggle>
		</mat-button-toggle-group>
	</form>
}
<div [hidden]="currentMode !== 'default'">
	<div fxLayout="column">
		<mat-card>
			<div class="editor-header">
				@if (!root) {
					<div>
						<mat-card-title>
							@if (type === 'object' || type === 'array') {
								<mat-icon (click)="toggleExpand()" class="mat-icon-rtl-mirror"
									>{{ expanded ? 'expand_more' : 'chevron_right' }}
								</mat-icon>
							}
							@if (showHeader) {
								<span style="font-size: 16px"> {{ propertyName }}</span>
							}
						</mat-card-title>
						@if (jsonSchema?.description && showHeader) {
							<mat-card-subtitle>
								{{ jsonSchema.description }}
							</mat-card-subtitle>
						}
					</div>
				}

				@if (formGroup && !['object', 'array'].includes(type)) {
					<div class="simple-value-entry">
						@if (type === 'string') {
							<mat-card-content>
								<form [formGroup]="formGroup">
									<mat-form-field fxFlex="1 0 auto">
										<input matInput formControlName="{{ propertyName }}" type="string" />
									</mat-form-field>
								</form>
							</mat-card-content>
						}
						@if (type === 'number') {
							<mat-card-content>
								<form [formGroup]="formGroup">
									<mat-form-field fxFlex="1 0 auto">
										<input matInput formControlName="{{ propertyName }}" type="number" />
									</mat-form-field>
								</form>
							</mat-card-content>
						}
						@if (type === 'boolean') {
							<mat-card-content>
								<form [formGroup]="formGroup">
									<mat-form-field fxFlex="1 0 auto">
										<mat-select formControlName="{{ propertyName }}">
											<mat-option [value]="false">False</mat-option>
											<mat-option [value]="true">True</mat-option>
										</mat-select>
									</mat-form-field>
								</form>
							</mat-card-content>
						}
						@if (type === 'null') {
							<mat-card-content> null </mat-card-content>
						}
					</div>
				}
				<div fxLayout="row">
					@if (removeSelf) {
						<mat-icon style="margin-top: 5px" class="pr2" (click)="removeSelf()">delete</mat-icon>
					}
					@if (type === 'object' || type === 'array') {
						<div>
							<button mat-icon-button [matMenuTriggerFor]="menu">
								<mat-icon style="margin-top: 5px" class="pr2">more_horiz</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								@if (type === 'object') {
									<button mat-menu-item (click)="openAdditionalPropertyEdit()">Add additional properties</button>
								}
								<button mat-menu-item (click)="toggleExpandAll()">{{ expandAll ? 'Collapse' : 'Expand' }} all</button>
							</mat-menu>
						</div>
					}
					@if (supportedTypes.length > 1) {
						<form [formGroup]="ownType">
							<mat-form-field>
								@if (!type) {
									<mat-label>Choose type</mat-label>
								}
								<mat-select formControlName="type">
									@for (choosableType of supportedTypes; track choosableType) {
										<mat-option [value]="choosableType">{{ choosableType }}</mat-option>
									}
								</mat-select>
							</mat-form-field>
						</form>
					}
				</div>
			</div>

			<div [hidden]="!root && !expanded">
				@if (type === 'object') {
					<mat-card-content>
						<div class="child-editors">
							@for (property of properties; track property) {
								<div class="child-editor-container">
									<app-schema-based-editor
										[jsonSchema]="property.sub_schema"
										[refs]="refs"
										[propertyName]="property.name"
										[parentFormGroup]="formGroup"
										[data]="property.value"
										[expandAll]="expandAll"
									>
									</app-schema-based-editor>
								</div>
							}
						</div>
						@if (jsonSchema.additionalProperties) {
							<div>
								@if (isAddingAdditionalProperty) {
									<div #addAdditionalProperty fxLayout="row">
										<form [formGroup]="additionalProperty">
											<mat-form-field fxFlex="1 0 auto">
												<input matInput formControlName="name" />
											</mat-form-field>
										</form>
										<button mat-button [disabled]="!additionalProperty.valid" (click)="addValue(false)">Add</button>
										<button mat-button (click)="isAddingAdditionalProperty = false">Cancel</button>
									</div>
								}
								<div class="child-editors">
									@for (value of values; track value) {
										<div class="child-editor-container">
											<app-schema-based-editor
												[jsonSchema]="jsonSchema.additionalProperties"
												[refs]="refs"
												[propertyName]="value.index"
												[parentFormGroup]="formGroup"
												[removeSelf]="removeValue(value)"
												[data]="value.value"
												[expandAll]="expandAll"
											>
											</app-schema-based-editor>
										</div>
									}
								</div>
							</div>
						}
						@if (!jsonSchema.additionalProperties) {
							<div>
								@if (isAddingAdditionalProperty) {
									<div #addAdditionalProperty fxLayout="row">
										<form [formGroup]="additionalProperty">
											<mat-form-field fxFlex="1 0 auto">
												<input matInput formControlName="name" />
											</mat-form-field>
										</form>
										<button mat-button [disabled]="!additionalProperty.valid" (click)="addValue(false)">Add</button>
										<button mat-button (click)="isAddingAdditionalProperty = false">Cancel</button>
									</div>
								}
								<div class="child-editors">
									@for (value of values; track value) {
										<div class="child-editor-container">
											<app-schema-based-editor
												[refs]="refs"
												[propertyName]="value.index"
												[parentFormGroup]="formGroup"
												[removeSelf]="removeValue(value)"
												[data]="value.value"
												[expandAll]="expandAll"
											>
											</app-schema-based-editor>
										</div>
									}
								</div>
							</div>
						}
					</mat-card-content>
				}

				@if (type === 'array') {
					<mat-card-content>
						<button mat-button (click)="addValue(true)">Add</button>
						<div class="child-editors">
							@for (value of values; track value) {
								<div class="child-editor-container">
									<app-schema-based-editor
										[jsonSchema]="jsonSchema.items"
										[refs]="refs"
										[propertyName]="value.index"
										[parentFormGroup]="formGroup"
										[removeSelf]="removeValue(value)"
										[showHeader]="false"
										[data]="value.value"
										[expandAll]="expandAll"
									>
									</app-schema-based-editor>
								</div>
							}
						</div>
					</mat-card-content>
				}
			</div>
		</mat-card>
	</div>
</div>

@if (currentMode === 'json') {
	<form [formGroup]="editjson" style="margin-top: 2em">
		<mat-form-field appearance="outline" fxFlex="100">
			<mat-label>Tfvars</mat-label>
			<textarea
				matInput
				id="tfvarsTextArea"
				cdkTextareaAutosize
				#autosize="cdkTextareaAutosize"
				formControlName="text"
				cdkAutosizeMinRows="1"
				cdkAutosizeMaxRows="100"
			></textarea>
		</mat-form-field>
	</form>
}
