<mat-sidenav-container fxFlexFill>
	<mat-sidenav-content>
		<div class="navigation">
			<div class="navigation-title">{{ selectedService }}</div>

			@if (limitedAccessEnabled) {
				@if (!selectedServiceEnabled) {
					<admonition-box class="mb2" type="error" fxLayoutAlign="start center" [noPadding]="true">
						Access limited
					</admonition-box>
				}
			}

			<div class="navigation-item-container">
				@for (item of nav.overview; track item) {
					@if (!item.isExternal) {
						<div class="navigation-item" [routerLink]="[item.path]" routerLinkActive="active">
							{{ item.displayName }}
						</div>
					}
					@if (item.isExternal) {
						<a class="navigation-item" [href]="item.path" target="_blank">{{ item.displayName }}</a>
					}
					<mat-divider class="mb2"></mat-divider>
				}
				@for (item of nav.sections; track item) {
					<div class="navigation-header">{{ item.name }}</div>
					@for (resource of item.resources; track resource) {
						@if (!resource.isExternal) {
							<div class="navigation-item" [routerLink]="[resource.path]" routerLinkActive="active">
								{{ resource.displayName }}
							</div>
						}
						@if (resource.isExternal) {
							<a class="navigation-item" [href]="resource.path" target="_blank">{{ resource.displayName }}</a>
						}
					}
					<mat-divider class="mb2"></mat-divider>
				}
			</div>
		</div>

		<div class="content-wrapper" fxLayout="column">
			@if (limitedAccessEnabled) {
				@if (!selectedServiceEnabled) {
					<admonition-box type="error" [noPadding]="true">
						Your Tenant currently does not have an active billing channel. As a result you might only have limited access to
						this service and some features may not work as expected. Please contact the sales representative of this tenant in
						order to setup a billing channel.
					</admonition-box>
				}
			}
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>

	@if (helpBoxPortal) {
		<mat-sidenav position="end" mode="side" [opened]="helpOpen" [style]="{ width: helpBoxComponent.width, 'border-left': 'none' }">
			<div class="helpbox-container" fxFlex>
				<div class="helpbox-title" fxLayoutAlign="space-between center">
					<span>Help</span>
					<button mat-icon-button (click)="toggleHelp()"><mat-icon>close</mat-icon></button>
				</div>
				@if (!helpboxOverlay) {
					<cdk-virtual-scroll-viewport itemSize="50" fxFlex>
						<div fxLayout="column">
							<ng-container [cdkPortalOutlet]="helpBoxPortal"></ng-container>
						</div>
					</cdk-virtual-scroll-viewport>
				}
				@if (helpboxOverlay) {
					<cdk-virtual-scroll-viewport itemSize="50" fxFlex>
						<div fxLayout="column" [innerHtml]="helpboxOverlay.content"></div>
					</cdk-virtual-scroll-viewport>
				}
			</div>
		</mat-sidenav>
	}
</mat-sidenav-container>
