<div fxFlex fxLayout="column" fxLayoutGap="8px">
	<p class="h3" for="customer">Assume Wildcard Role</p>
	<!-- Tenant ID -->
	<mcm-customer-select #tenant></mcm-customer-select>

	<!-- Role Name -->
	<div fxFlex fxLayout="row" fxLayoutGap="12px">
		<mat-form-field class="pb1">
			<mat-label>Role Type</mat-label>
			<mat-select [(value)]="roleType" (selectionChange)="changeRoleType($event.value)">
				<mat-option value="role">Role</mat-option>
				<mat-option value="managed-role">Managed-Role</mat-option>
			</mat-select>
			<!-- <mat-hint>Select the role type you want to use</mat-hint> -->
		</mat-form-field>
		<mat-form-field class="pb1" style="width: 100%">
			<mat-label for="customer">Role Name</mat-label>
			<input matInput #roleName [matAutocomplete]="roleAutofill" tabindex="2" />
			<mat-autocomplete #roleAutofill="matAutocomplete">
				@for (role of suggestedRoles; track role) {
					<mat-option [value]="role">{{ role }}</mat-option>
				}
			</mat-autocomplete>
			@if (this.suggestedRoles.length === 0) {
				<mat-hint>No role suggestions available</mat-hint>
			}
		</mat-form-field>
	</div>

	<button
		mat-raised-button
		color="primary"
		type="submit"
		(click)="assumeWildcardRole(tenant.value, roleType, roleName.value)"
		[disabled]="tenant.value.length === 0 || roleName.value.length === 0"
	>
		Switch Context
	</button>
</div>
