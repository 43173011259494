import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { RectComponent } from './skeleton-rect.component';

@Directive({
	selector: '[skeleton]',
})
export class SkeletonDirective implements OnChanges {
	@Input('skeleton') isLoading = false;
	@Input() skeletonRepeat = 5;
	@Input() width: string = 'rand';
	@Input() height: string = '30px';
	@Input() className: string = 'mt2';

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isLoading) {
			this.viewContainerRef.clear();

			if (changes.isLoading.currentValue) {
				Array.from({ length: this.skeletonRepeat }).forEach(() => {
					const ref = this.viewContainerRef.createComponent(RectComponent);

					Object.assign(ref.instance, {
						width: this.width === 'rand' ? `${this.randomNumberFromInterval(30, 90)}%` : this.width,
						height: this.height,
						className: this.className,
					});
				});
			} else {
				this.viewContainerRef.createEmbeddedView(this.templateRef);
			}
		}
	}

	private randomNumberFromInterval(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
}
