import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'hyperlink-renderer',
	template: `<a [routerLink]="this.link">{{ this.displayValue }}</a>`,
	styles: [
		`
			a:visited {
				color: #0000ee;
			}
		`,
	],
})
export class HyperlinkCellRenderer implements AgRendererComponent {
	public displayValue: any = undefined;
	public link: any = undefined;

	constructor(private router: Router) {}

	agInit(params: ICellRendererParams): void {
		if (params.value) {
			this.displayValue = params.value.displayName;
			this.link = params.value.link;
		}
	}

	refresh(params: ICellRendererParams): boolean {
		if (params.value) {
			this.displayValue = params.value.displayName;
			this.link = params.value.link;
			return true;
		}
		return false;
	}

	navigate() {
		this.router.navigate([this.link]);
	}
}
