import { AgGridAngular, AngularFrameworkComponentWrapper, AngularFrameworkOverrides } from '@ag-grid-community/angular';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { Component, ElementRef, HostBinding, OnInit, Renderer2, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { DEFAULT_TABLE_ROWS } from 'src/environments/global.config';
import { CancomTableLoadingOverlay } from './loading-overlay/cancom-table-loading.component';

@Component({
	selector: 'cancom-table',
	template: '',
	styleUrls: ['./cancom-table.component.scss'],
	providers: [AngularFrameworkOverrides, AngularFrameworkComponentWrapper],
	encapsulation: ViewEncapsulation.None,
})
export class CancomTableComponent extends AgGridAngular implements OnInit {
	@HostBinding('style.min-height') public minHeight = '300px';

	// Set default values
	public paginationPageSize: number = DEFAULT_TABLE_ROWS;
	public cacheBlockSize: number | undefined = DEFAULT_TABLE_ROWS;
	public suppressRowClickSelection = true;
	public groupAllowUnbalanced = true;
	public defaultColumnDefinition = {
		sortable: true,
		filter: true,
		resizable: true,
		enableRowGroup: true,
		minWidth: 100,
		flex: 1,
	};
	public loadingOverlayComponent: any = CancomTableLoadingOverlay;

	// Add Custom Cell Data Types to Ag Grid
	public dataTypeDefinitions: any = {
		hyperLink: {
			extendsDataType: 'object',
			baseDataType: 'object',
			valueFormatter: (params: ValueFormatterParams) => (params.value == null ? '' : params.value.displayName),
			dataTypeMatcher: (value: any) => value && !!value.displayName && !!value.link,
		},
	};

	constructor(
		elementDef: ElementRef,
		viewContainerRef: ViewContainerRef,
		angularFrameworkOverrides: AngularFrameworkOverrides,
		angularFrameworkComponentWrapper: AngularFrameworkComponentWrapper,
		private renderer: Renderer2
	) {
		// Set default theme and remove overwritten themes
		for (const item of elementDef.nativeElement.classList) {
			if (item.includes('ag-theme-')) {
				console.error('AgTheme provided. Please do not add any of the ag-theme styles.');
				renderer.removeClass(elementDef.nativeElement, item);
			}
		}
		renderer.addClass(elementDef.nativeElement, 'ag-theme-material');

		super(elementDef, viewContainerRef, angularFrameworkOverrides, angularFrameworkComponentWrapper);
	}

	ngOnInit(): void {
		// Add optional Component defaultColumnDef to our preset defaultColumnDef
		this.defaultColDef = { ...this.defaultColumnDefinition, ...(this.defaultColDef || {}) };
	}
}
