<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">{{ name }} Editor</p>
	<div fxLayout="column">
		<mat-form-field>
			<mat-label> {{ data.label }} </mat-label>
			<textarea
				matInput
				cdkTextareaAutosize
				#autosize="cdkTextareaAutosize"
				cdkAutosizeMinRows="1"
				cdkAutosizeMaxRows="5"
				[value]="value"
				#text
			>
			</textarea>
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		<button mat-raised-button (click)="cancel()">Cancel</button>
		<button mat-raised-button color="primary" (click)="update(text.value)">{{ data.btnText }}</button>
	</div>
</div>
