// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	stage: 'dev',
	url: 'https://portal.cancom.dev',
	keycloak: {
		url: 'https://sso.cancom.dev/auth/',
		realm: 'cancom-dev',
		clientId: 'reports.cc-mase-automation.de',
	},
	contactEMail: 'portal@ihre-meinung-interessiert-uns.net',
	supportEMail: 'ccp-support@cancom.io',
	trackingId: 2,
	assets: 'https://portal-assets.cancom.io',
	feature: {
		limitedAccessBanner: false,
	},
	api: {
		drive: 'https://drive-api.portal.cancom.dev',
		tRex: 'https://resource-explorer.portal.cancom.dev',
		iam: 'https://iam.portal.cancom.dev',
		managedOS: 'https://managed-os.portal.cancom.dev',
		managedServer: 'https://dev-wbh01.int.cc-mase.com:7053',
		WindowsOS: 'https://windows-os.portal.cancom.dev',
		carRental: 'https://car-rental.portal.cancom.dev',
		xaasonazure: 'https://xaas-azure.portal.cancom.dev',
		webpresence: 'https://domdns.portal.cancom.dev',
		// webpresence: 'https://vpswqliuu8.execute-api.eu-central-1.amazonaws.com/dev',
		genericServices: 'https://generic-services.portal.cancom.dev',
		azurelz: 'https://az-landing.portal.cancom.dev',
		customerDb: 'https://customer-db.portal.cancom.dev',
		serviceRegistry: 'https://service-registry.portal.cancom.dev',
		corc: 'https://corc.portal.cancom.dev',
		cmsmgw: 'https://cmsmgw.cc-mase-interfaces.de/dev',
		cmsmgwaddons: 'https://j2xydo8d6j.execute-api.eu-central-1.amazonaws.com/dev/dev',
		ipmanagement: 'https://ip-management.portal.cancom.dev',
		sslMonitor: 'https://ssl-monitor.portal.cancom.dev', // 'http://localhost:8000',
		eventbridge: 'https://event-bridge.portal.cancom.dev',
		miaMuseum: 'https://mia-museum.portal.cancom.dev',
		meteringEngine: 'https://metering-engine.portal.cancom.dev',
		smsAlert: 'https://sms-notify.portal.cancom.dev',
		auditLog: 'https://audit-log.portal.cancom.dev',
		dciNg: 'https://dci-ng.portal.cancom.dev',
		accountManagement: 'https://myaccount-api.portal.cancom.dev',
		dynamicCloud: 'https://dynamic-cloud.portal.cancom.dev',
		simpleVpn: 'https://simple-vpn.portal.cancom.dev',
		terraformManagement: 'https://oi14xfsi68.execute-api.eu-central-1.amazonaws.com/dev',
	},
	external: {
		drive: 'https://drive.portal.cancom.dev',
		myaccount: 'https://myaccount.portal.cancom.dev',
	},
	// agGridLicenseKey: 'CompanyName=CANCOM Managed Services GmbH,LicensedApplication=CANCOM Cloud Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-038355,SupportServicesEnd=15_February_2024_[v2]_MTcwNzk1NTIwMDAwMA==2a26498da9c6fd1f45eaed21f9ddad92'
	agGridLicenseKey:
		'Using_this_{AG_Grid}_Enterprise_key_{AG-051740}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CANCOM_Managed_Services_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{CANCOM_Cloud_Portal}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{CANCOM_Cloud_Portal}_need_to_be_licensed___{CANCOM_Cloud_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_February_2025}____[v3]_[01]_MTczOTQ5MTIwMDAwMA==66576d35dbb87dc42a57f8996a29bf3a',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
