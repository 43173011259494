import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CountdownModule } from 'ngx-countdown';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { MatomoTracker, NgxMatomoModule, NgxMatomoRouterModule } from 'ngx-matomo-client';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './components/auth/auth.guard';
import { ContextChangeComponent } from './components/context-change/context-change.component';
import { ExternalPortalIncluderComponent } from './components/external-portal-includer/external-portal-includer.component';
import { LoginComponent } from './components/login/login.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { CustomerdbService } from './components/services/customer-db/services/customer-db.service';
import { KeycloakBearerInterceptor } from './interceptors/keycloak-bearer.interceptor';
import { RoleCredentialInterceptor } from './interceptors/role-credential.interceptor';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { CacheFilterService } from './services/cache-filter.service';
import { DateService } from './services/date.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { UserProfileService } from './services/userprofile.service';
import { SharedComponentsModule } from './shared/shared-components.module';

function initializeKeycloak(keycloak: KeycloakService, router: Router) {
	return () =>
		keycloak
			.init({
				config: {
					url: environment.keycloak.url,
					realm: environment.keycloak.realm,
					clientId: environment.keycloak.clientId,
				},
				enableBearerInterceptor: false,
				initOptions: {
					onLoad: 'check-sso',
					silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
				},
			})
			.catch(() => router.navigate(['/maintenance']));
}

function initializeMatomo(tracker: MatomoTracker) {
	return () => {
		tracker.setRequestMethod('GET');
		return tracker;
	};
}

@NgModule({
	declarations: [AppComponent, LoginComponent, ExternalPortalIncluderComponent, ContextChangeComponent, MaintenanceComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CountdownModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		KeycloakAngularModule,
		MatButtonModule,
		MatCardModule,
		MatChipsModule,
		MatDialogModule,
		MatDividerModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSidenavModule,
		MatToolbarModule,
		MatTooltipModule,
		NgxMatomoModule.forRoot({
			trackerUrl: 'https://stats.cancom.io/',
			disabled: environment.trackingId < 0,
			siteId: environment.trackingId,
			scriptUrl: 'https://stats.cancom.io/bla.js',
			trackerUrlSuffix: 'bla.ccp',
		}),
		NgxMatomoRouterModule,
		ReactiveFormsModule,
		SharedComponentsModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService, Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeMatomo,
			deps: [MatomoTracker],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: KeycloakBearerInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RoleCredentialInterceptor,
			multi: true,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import('highlight.js/lib/core'),
				languages: {
					json: () => import('highlight.js/lib/languages/json'),
					ini: () => import('highlight.js/lib/languages/ini'),
				},
			},
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'fill',
			},
		},
		ApiService,
		AppService,
		AuthGuard,
		CustomerdbService,
		UserProfileService,
		LoadingIndicatorService,
		CacheFilterService,
		DateService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
