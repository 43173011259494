import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment.dev';
import { TextInputeditorDialogComponent } from '../textInputeditor-dialog/textInputeditor-dialog.component';

interface Endpoint {
	serviceName: string;
	endpoint: string;
}

@Component({
	selector: 'app-overwrite-backend-endpoint-dialog',
	templateUrl: './overwrite-backend-endpoint-dialog.component.html',
	styleUrls: ['./overwrite-backend-endpoint-dialog.component.scss'],
})
export class OverwriteBackendEndpointDialogComponent {
	public environmentEndpoints: { [key: string]: string } = environment.api;
	public endpoints: Endpoint[] = [];

	public displayedColumns: string[] = ['serviceName', 'endpointValue', 'edit', 'reset'];

	private storageKey = 'serviceBackendEndpoints';

	constructor(
		public dialogRef: MatDialogRef<OverwriteBackendEndpointDialogComponent>,
		private readonly dialog: MatDialog
	) {
		this.loadEndpointsFromEnv();
		this.loadEndpointsFromLocalstorage();
	}

	public loadEndpointsFromEnv() {
		for (const key in this.environmentEndpoints) {
			this.endpoints.push({ serviceName: key, endpoint: this.environmentEndpoints[key] });
		}
	}

	public loadEndpointsFromLocalstorage() {
		if (localStorage.getItem(this.storageKey) !== null) {
			const overwrittenEndpoints = JSON.parse(localStorage.getItem(this.storageKey)!);
			for (const overwrittenEndpoint of overwrittenEndpoints) {
				const displayEndpoint = this.endpoints.find((endpoint) => endpoint.serviceName === overwrittenEndpoint.serviceName);
				if (displayEndpoint) {
					displayEndpoint.endpoint = overwrittenEndpoint.endpoint;
				}
			}
		}
	}

	public editBackendEndpoint(serviceName: string, endpoint: string) {
		try {
			this.dialog
				.open(TextInputeditorDialogComponent, {
					minWidth: '500px',
					data: { value: endpoint, name: `${serviceName} Endpoint` },
				})
				.afterClosed()
				.subscribe(async (newEndpoint) => {
					if (newEndpoint) {
						this.writeEndpointToLocalstorage(serviceName, newEndpoint);
					}
				});
		} catch (e) {
			console.log(e);
		}
	}

	public writeEndpointToLocalstorage(serviceName: string, endpoint: string) {
		if (localStorage.getItem(this.storageKey) !== null) {
			const currentStorage: Endpoint[] = JSON.parse(localStorage.getItem(this.storageKey)!);
			const existingElement = currentStorage.find((endpoint) => endpoint.serviceName === serviceName);

			if (existingElement) {
				existingElement.endpoint = endpoint;
			} else {
				currentStorage.push({ serviceName, endpoint });
			}
			localStorage.setItem(this.storageKey, JSON.stringify(currentStorage));
		} else {
			localStorage.setItem(this.storageKey, JSON.stringify([{ serviceName, endpoint }]));
		}
		this.loadEndpointsFromLocalstorage();
	}

	public resetBackendEndpoint(serviceName: string) {
		if (localStorage.getItem(this.storageKey) !== null) {
			let currentStorage: Endpoint[] = JSON.parse(localStorage.getItem(this.storageKey)!);
			currentStorage = currentStorage.filter((endpoint) => endpoint.serviceName !== serviceName);
			localStorage.setItem(this.storageKey, JSON.stringify(currentStorage));

			this.endpoints = [];
			this.loadEndpointsFromEnv();
			this.loadEndpointsFromLocalstorage();
		}
	}
}
