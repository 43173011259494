import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { DEFAULT_TABLE_ROWS, SERVICE_LOOKUP } from 'src/environments/global.config';

export type CustomerDbParams = {
	limit: number;
	startValue?: number;
};

interface _CustomerInformation {
	MCM_ACCOUNT_ID: string;
	SNOW_BUSINESS_UNIT_DESCRIPTION?: string;
	SNOW_BUSINESS_UNIT_ID?: string;
	SNOW_BUSINESS_UNIT_NAME?: string;
	SNOW_NUMBER_ID: string;
	SNOW_SYS_ID: string;
	SHORT_NAME_ID?: string;
	is_onboarded: boolean;

	name: string;
	u_report_language: string;
}

export interface CustomerInformation extends _CustomerInformation {
	body: _CustomerInformation;
}

@Injectable({
	providedIn: 'root',
})
export class CustomerdbService {
	protected readonly api: string = environment.api.customerDb;
	public searchedCustomerValue?: string = undefined;

	constructor(
		protected readonly http: HttpClient,
		protected readonly keycloakService: KeycloakService
	) {
		const value = SERVICE_LOOKUP('customerDb');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	public async searchCustomer(customerName?: string, params: CustomerDbParams = { limit: DEFAULT_TABLE_ROWS }): Promise<any> {
		const url = `${this.api}/v1/CustomersOS`;
		let queryParams = new HttpParams().set('limit', params.limit);

		if (customerName) {
			queryParams = queryParams.append('filter', customerName);
		}

		if (params.startValue) {
			queryParams = queryParams.append('next', params.startValue);
		}
		return this.http.get<any>(url, { params: queryParams }).toPromise();
	}

	public async listCustomers(limit: number = 10, startValue?: number): Promise<any> {
		let url = `${this.api}/v1/CustomersOS?limit=${limit}`;
		if (startValue) {
			url = `${url}&next=${startValue}`;
		}
		return this.http.get<any>(url).toPromise();
	}

	public async syncCustomer(id: string): Promise<any> {
		return this.http.put<any>(`${this.api}/v1/Customers/${id}`, {}).toPromise();
	}

	public async getCustomerInfo(mcmAccountID: string): Promise<CustomerInformation> {
		return this.http.get<any>(`${this.api}/v1/Customers/${mcmAccountID}`).toPromise();
	}

	public async createVirtualTenant(tenantName: string): Promise<any> {
		return this.http.post<any>(`${this.api}/v1/Customers/addVirtualCustomer`, { name: tenantName }).toPromise();
	}
}
