import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';
import { CustomerdbService } from '../components/services/customer-db/services/customer-db.service';
import { NotificationBarService } from './notification-bar.service';

export interface UserProfile {
	roles: string[];
	sub: string;
	account: string;
	tenant: string;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	name: string;
	preferred_username: string;
}

export interface Session {
	jwt: string;
}

type OptionalString = string | undefined;

@Injectable({
	providedIn: 'root',
})
export class UserService {
	protected readonly api: string = environment.api.iam;

	private userInfo?: UserProfile = undefined;
	private services: { [service: string]: { permissions: any; enabled: boolean } } = {};
	private initialized: boolean = false;

	private session?: Session = undefined;
	private _sessionToken: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);

	private _userName: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);
	private _principal: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);
	private _tenant: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);
	private _tenantName: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);
	private _principalName: BehaviorSubject<OptionalString> = new BehaviorSubject<OptionalString>(undefined);
	public _selectedTenant?: string = undefined;

	private _originalPrincipal: OptionalString = undefined;
	private _originalTenant: OptionalString = undefined;

	private urlPath?: string = undefined;

	constructor(
		private readonly keycloak: KeycloakService,
		private readonly http: HttpClient,
		private readonly customerService: CustomerdbService,
		private router: Router,
		private notificationBarService: NotificationBarService
	) {
		const service = SERVICE_LOOKUP('iam');
		if (service != null && service.endpoint !== this.api) {
			this.api = service.endpoint;
		}
	}

	public async getUserInfo(): Promise<UserProfile> {
		if (!this.userInfo) {
			if (await this.keycloak.isLoggedIn()) {
				this.userInfo = (await this.keycloak.getKeycloakInstance().loadUserInfo()) as UserProfile;
			}
		}

		if (!this.userInfo) {
			throw new Error();
		}

		return this.userInfo;
	}

	private principalToPrincipalName(principal: OptionalString) {
		if (!!principal) {
			const parts = principal.split(':');
			if (parts[4] === 'user') {
				this._principalName.next(this.userInfo?.given_name + ' ' + this.userInfo?.family_name);
			} else {
				this._principalName.next(parts[5]);
			}
		} else {
			this._principalName.next(undefined);
		}
	}

	private async updatePrincipalState(userInfo: UserProfile) {
		this._userName.next(userInfo.given_name + ' ' + userInfo.family_name);
		this._principal.next('crn:' + userInfo.tenant + '::iam:user:' + userInfo.email);
		this._tenant.next(userInfo.tenant);

		try {
			const customerInfo = await this.customerService.getCustomerInfo(userInfo.tenant);
			this._tenantName.next(customerInfo.name);
		} catch (err) {
			this._tenantName.next(userInfo.tenant);
		}

		this._originalPrincipal = 'crn:' + userInfo.tenant + '::iam:user:' + userInfo.email;
		this._originalTenant = userInfo.tenant;
	}

	public async initialize() {
		// KeyCloak adapter is already initialized
		if (this.initialized) {
			return;
		}

		// Setup principal subscription
		this.principal.subscribe((p: OptionalString) => this.principalToPrincipalName(p));

		this.keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
			if (event.type === KeycloakEventType.OnAuthRefreshError) {
				this.keycloak.login();
			}
		});

		document.addEventListener('visibilitychange', () => {
			if (!document.hidden) {
				this.keycloak.updateToken();
			}
		});

		if (!this.userInfo) {
			if (await this.keycloak.isLoggedIn()) {
				this.userInfo = (await this.keycloak.getKeycloakInstance().loadUserInfo()) as UserProfile;
			}
		}

		if (!this.userInfo) {
			throw new Error();
		}

		this.updatePrincipalState(this.userInfo);
		this.initialized = true;
	}

	public async getCRN(): Promise<string> {
		if (!this.userInfo) {
			await this.getUserInfo();
		}
		return 'crn:' + this.userInfo!.tenant + '::iam:user:' + this.userInfo!.email;
	}

	public async isLoggedIn(): Promise<boolean> {
		return this.keycloak.isLoggedIn();
	}

	public async getPermissions(service: string): Promise<{ [p: string]: any }> {
		if (!(service in this.services)) {
			await this.refreshServiceInfo(service).catch((err) => {
				throw err;
			});
		}
		return this.services[service].permissions;
	}

	public async getServiceState(service: string): Promise<boolean> {
		if (!(service in this.services)) {
			await this.refreshServiceInfo(service).catch((err) => {
				throw err;
			});
		}
		return this.services[service].enabled;
	}

	public async refreshServiceInfo(service: string): Promise<void> {
		const permissionInfo = await this.http
			.get<any>(this.api + '/v1/CallerPolicyDocument/' + service)
			.toPromise()
			.catch((err) => {
				throw err;
			});
		this.services[service] = { permissions: permissionInfo.policy, enabled: permissionInfo.serviceEnabled };
	}

	public get currentTenant(): OptionalString {
		return this._tenant.value;
	}

	public async assumeRole(role: string) {
		const urlPath = window.location.pathname;
		await this.router.navigateByUrl('/changeCtx', { skipLocationChange: true });

		if (this.getSession() != undefined) {
			await this.leaveRole();
		}

		try {
			const permissionInfo = await this.http.post<any>(this.api + '/v1/AssumeRole', { role }).toPromise();

			const currentTenant = this._tenant.value;
			const splitRole = role.split(':');
			const targetTenant = splitRole[1];
			const targetRole = splitRole[splitRole.length - 2] + ':' + splitRole[splitRole.length - 1];
			let targetDisplayName = '';

			// if we have currently have access to the customer db, query it for the customer name
			if (currentTenant === 'cancom') {
				try {
					const info = await this.customerService.getCustomerInfo(targetTenant);
					targetDisplayName = info.body.name;
					this._tenantName.next(info.body.SHORT_NAME_ID || info.body.name || targetTenant);
				} catch {
					this._tenantName.next(targetTenant);
				}
			} else {
				this._tenantName.next(targetTenant);
			}

			this.session = {
				jwt: permissionInfo.jwt,
			};
			this._sessionToken.next(this.session.jwt);

			this._principal.next(role);
			this._tenant.next(targetTenant);

			for (const service in this.services) {
				this.refreshServiceInfo(service);
			}

			const currentRole = localStorage.getItem('currentRole');

			this.writeCurrentRoleToLocalStorage(role);
			this.writeLastUsedRolesToLocalStorage(targetTenant, targetRole, targetDisplayName);

			let targetLocation = '/dashboard';
			if ((await this.getCRN()) !== currentRole) {
				targetLocation = urlPath;
			}

			setTimeout(() => this.router.navigateByUrl(targetLocation, { skipLocationChange: false }), 200);
		} catch (err) {
			await this.router.navigateByUrl(urlPath, { skipLocationChange: true });
			if (err instanceof HttpErrorResponse) {
				const message = err.error.message;
				this.notificationBarService.notify('error', 'Error', message);
			}
			this.notificationBarService.notify('error', 'Error', 'Could not switch context. Maybe you are missing permissons?');
			this.writeCurrentRoleToLocalStorage(await this.getCRN());
		}
	}

	private writeLastUsedRolesToLocalStorage(tenant: string, role: string, displayName: string = ''): void {
		let localStorageValue: any[];
		if (localStorage.getItem('lastUsedRoles') == null) {
			localStorageValue = [{ tenant: tenant, displayName: displayName, roles: [role] }];
		} else {
			localStorageValue = JSON.parse(localStorage.getItem('lastUsedRoles')!);
			const existIndex = localStorageValue.findIndex((item: any) => item.tenant === tenant);
			if (existIndex === -1) {
				// add tenant to the front of array if not already in it and cutoff after 5
				localStorageValue.unshift({ tenant, displayName, roles: [role] });
				if (localStorageValue.length > 5) {
					localStorageValue.splice(-1);
				}
			} else {
				// add role to existing tenant and move to front of array (newest position)
				const existingTenant = localStorageValue.splice(existIndex, 1)[0];
				if (!existingTenant.roles.includes(role)) {
					existingTenant.roles.push(role);
				}
				localStorageValue.unshift(existingTenant);
			}
		}
		localStorage.setItem('lastUsedRoles', JSON.stringify(localStorageValue));
	}

	public removeLastUsedRoleFromLocalStorage(tenant: string, role: string): void {
		let localStorageValue: any[];
		if (localStorage.getItem('lastUsedRoles') != null) {
			localStorageValue = JSON.parse(localStorage.getItem('lastUsedRoles')!);

			const tenantIndex = localStorageValue.findIndex((item: any) => item.tenant === tenant);
			if (tenantIndex !== -1) {
				const tenant = localStorageValue[tenantIndex];
				if (tenant.roles.includes(role)) {
					tenant.roles.splice(
						tenant.roles.findIndex((existingRole: any) => existingRole === role),
						1
					);
				}
				if (tenant.roles.length == 0) {
					localStorageValue.splice(tenantIndex, 1);
				} else {
					localStorageValue.splice(tenantIndex, 1, tenant);
				}
			}
			localStorage.setItem('lastUsedRoles', JSON.stringify(localStorageValue));
		}
	}

	private writeCurrentRoleToLocalStorage(role: string): void {
		localStorage.setItem('currentRole', role);
	}

	public async connectRole(user: string, role: string) {
		const connectInfo = await this.http.post<any>(this.api + '/v1/Connect', { user, role }).toPromise();
		console.log(connectInfo);
	}

	public async leaveRole() {
		this.urlPath = this.router.url;
		await this.router.navigate(['/changeCtx']);

		this.session = undefined;
		this._sessionToken.next(undefined);

		try {
			const userInfo = await this.getUserInfo();

			this.writeCurrentRoleToLocalStorage(await this.getCRN());
			this.updatePrincipalState(userInfo);

			for (const service in this.services) {
				this.refreshServiceInfo(service);
			}
		} catch {
			this.userInfo = undefined;
			this.keycloak.logout();
		}

		setTimeout(() => this.router.navigate([this.urlPath]), 800);
	}

	public getSession(): Session | undefined {
		return this.session;
	}

	public get sessionToken(): Observable<OptionalString> {
		return this._sessionToken.asObservable();
	}

	public get userName(): Observable<OptionalString> {
		return this._userName.asObservable();
	}

	public get tenant(): Observable<OptionalString> {
		return this._tenant.asObservable();
	}

	public get tenantName(): Observable<OptionalString> {
		return this._tenantName.asObservable();
	}

	public get principal(): Observable<OptionalString> {
		return this._principal.asObservable();
	}

	public get principalName(): Observable<OptionalString> {
		return this._principalName.asObservable();
	}

	public get originalTenant(): OptionalString {
		return this._originalTenant;
	}

	public get originalPrincipal(): OptionalString {
		return this._originalPrincipal;
	}
}
