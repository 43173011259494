import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';
import { IamPolicyService } from './iam-policy.service';

export interface RoleType {
	tenant: string;
	displayName: string;
	roles: string[];
}

export interface IamPrincipal {
	createdAt: string;
	createdBy: string;
	principal: string;
	session_hash: string;
	description: string;
	tenant: string;
	trustPolicy?: { Principals: string[] };
	team: string;
}

export interface AssumablePrincipal {
	[crn: string]: string;
}

export interface AssumableRolesResponse {
	assumablePrincipals: { [crn: string]: string };
	assumablePrincipalGroups: { [groupCrn: string]: { [crn: string]: string } };
	principal: string;
	trustedPrincipals: any;
}

@Injectable({
	providedIn: 'root',
})
export class IamRoleService {
	protected readonly api: string = environment.api.iam;
	constructor(
		private readonly http: HttpClient,
		private readonly policyService: IamPolicyService
	) {
		const value = SERVICE_LOOKUP('iam');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	/**
	 * Get a role by its CRN
	 * @param role The role to get details about
	 */
	public async getRole(role: string): Promise<IamPrincipal> {
		return this.http.get<IamPrincipal>(this.api + '/v1/Roles/' + role).toPromise();
	}

	/**
	 * Get the list of all IAM users associated with this account
	 * @returns A list of IAM Users
	 */
	public async getRoles(): Promise<IamPrincipal[]> {
		return this.http.get<IamPrincipal[]>(this.api + '/v1/Roles').toPromise();
	}

	public async deleteRole(user: string): Promise<any> {
		return this.http.delete(this.api + '/v1/Roles/' + user).toPromise();
	}

	/**
	 * Get a managed-role by its CRN
	 * @param managedRole The managed-role to get details about
	 */
	public async getManagedRole(managedRole: string): Promise<IamPrincipal> {
		return this.http.get<IamPrincipal>(this.api + '/v1/Roles/' + managedRole).toPromise();
	}

	/**
	 * Describe a managed-role by its CRN
	 * @param role The managed-role to get details about
	 */
	public async describeManagedRole(managedRole: string): Promise<IamPrincipal> {
		return this.http.get<IamPrincipal>(this.api + '/v1/Roles/' + managedRole).toPromise();
	}

	/**
	 * Get the list of all managed-roles associated with this account (CANCOM only)
	 * @returns A list of managed-roles
	 */
	public async getManagedRoles(): Promise<IamPrincipal[]> {
		return this.http.get<IamPrincipal[]>(this.api + '/v1/Roles').toPromise();
	}

	/**
	 * Get the list of all updated roles associated with this account (CANCOM only)
	 * @returns A list of updated roles
	 */
	public async updateRole(user: string, updatedDescription: string, updatedTeam: string): Promise<any> {
		return this.http.put(this.api + '/v1/Roles/' + user, { description: updatedDescription, team: updatedTeam }).toPromise();
	}

	/**
	 * Delete a specific managed-role
	 * @param managedRole the managed-role to be deleted
	 * @returns
	 */
	public async deleteManagedRole(managedRole: string): Promise<any> {
		return this.http.delete(this.api + '/v1/ManagedRoles/' + managedRole).toPromise();
	}

	/**
	 * Get the list of all updated managed-role associated with this account (CANCOM only)
	 * @returns A list of updated roles
	 */
	public async updateManagedRole(user: string, updatedDescription: string, updatedTeam: string): Promise<any> {
		return this.http.put(this.api + '/v1/ManagedRoles/' + user, { description: updatedDescription, team: updatedTeam }).toPromise();
	}

	public async getAssumableRoles(principal: string): Promise<AssumableRolesResponse> {
		return this.http.get<AssumableRolesResponse>(this.api + '/v1/TrustRelations/' + principal).toPromise();
	}

	public async addAssumableRole(principal: string, role: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustRelations/' + principal, { addAssumablePrincipal: role }).toPromise();
	}

	public async removeAssumableRole(principal: string, role: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustRelations/' + principal, { remAssumablePrincipal: role }).toPromise();
	}

	public async overwriteTrustRelation(principal: string, roles: string[]): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustRelations/' + principal, { trustRelations: roles }).toPromise();
	}

	public async addTrustedPrincipal(updatedPrincipal: string, addedPrincipal: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustRelations/' + updatedPrincipal, { addTrustedPrincipal: addedPrincipal }).toPromise();
	}

	public async removeTrustedPrincipal(updatedPrincipal: string, remPrincipal: string): Promise<any> {
		return this.http.put<any>(this.api + '/v1/TrustRelations/' + updatedPrincipal, { remTrustedPrincipal: remPrincipal }).toPromise();
	}

	public async getAssumableAndWildcardRoles(crn: string): Promise<{ assumableRoles: RoleType[]; wildcardRoles: string[] }> {
		const assumableRoles: RoleType[] = [];
		let wildcardRoles: string[] = [];

		try {
			const roleResponse = await this.getAssumableRoles(crn);
			let combinedAssumableRoles: { [crn: string]: string } = { ...roleResponse.assumablePrincipals };

			for (const group in roleResponse.assumablePrincipalGroups) {
				combinedAssumableRoles = { ...combinedAssumableRoles, ...roleResponse.assumablePrincipalGroups[group] };
			}

			for (const role in combinedAssumableRoles) {
				const splitRole = role.split(':');
				const _tenant = splitRole[1];
				const _role = splitRole[splitRole.length - 2] + ':' + splitRole.pop()!;

				if (assumableRoles.find((role) => role.tenant == _tenant)) {
					const index = assumableRoles.indexOf(assumableRoles.find((role) => role.tenant == _tenant)!);
					assumableRoles[index].roles.push(_role);
				} else {
					assumableRoles.push({
						tenant: _tenant,
						displayName: combinedAssumableRoles[role],
						roles: [_role],
					});
				}
			}

			const _wildcardRoles = assumableRoles.find((assumableRole) => assumableRole.tenant == '*');
			if (_wildcardRoles) {
				wildcardRoles = assumableRoles.splice(assumableRoles.indexOf(_wildcardRoles), 1)[0].roles;
			}
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}

		return { assumableRoles, wildcardRoles };
	}
}
