import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IamRoleService, RoleType } from './components/iam/services/iam-role.service';
import { CustomerInformation, CustomerdbService } from './components/services/customer-db/services/customer-db.service';
import { ServiceRegistryService } from './components/services/service-registry/services/service-registry.service';
import { AppService } from './services/app.service';
import { NotificationBarService } from './services/notification-bar.service';
import { ThemeService } from './services/theme.service';
import { UserService } from './services/user.service';
import { AssumableRoleWildcardDialogComponent } from './shared/dialogs/assumable-role-wildcard-dialog/assumable-role-wildcard-dialog.component';
import { OverwriteBackendEndpointDialogComponent } from './shared/dialogs/overwrite-backend-endpoint-dialog/overwrite-backend-endpoint-dialog.component';
import { ReassumableRoleWildcardDialogComponent } from './shared/dialogs/reassumable-role-wildcard-dialog/reassumable-role-wildcard-dialog.component';

export interface UserInfo {
	tenant: string;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	name: string;
	preferred_username: string;
	roles: string[];
	sub: string;
}

export type ServiceType = {
	name: string;
	displayName: string;
	route: string;
};

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	// encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
	public stage = environment.stage;
	public sidenavOpen = true;
	public currentYear = new Date().getFullYear();
	public crn: string = '';
	public isLoggedIn = false;
	public userInfo: UserInfo | null = null;
	public assumableRoles: RoleType[] = [];
	public wildcardRoles: string[] = [];
	public customers: CustomerInformation[] = [];
	public lastUsedRoles: RoleType[] = [];
	public retrieveServices: any[] = [];
	public selectedService?: string = undefined;
	public dateDiff: number | undefined = undefined;
	public canEditEndpoint: boolean = false;
	public services: ServiceType[] = [{ name: 'Dashboard', displayName: 'Dashboard', route: 'dashboard' }];
	public subscriptions: Subscription[] = [];
	public supportEMail: string = environment.supportEMail;

	constructor(
		private clipboard: Clipboard,
		private keycloak: KeycloakService,
		private notificationBarService: NotificationBarService,
		private readonly customerdbService: CustomerdbService,
		private readonly dialog: MatDialog,
		private readonly roleService: IamRoleService,
		private readonly router: Router,
		private readonly serviceRegistryService: ServiceRegistryService,
		public readonly appService: AppService,
		public readonly userService: UserService,
		public themeService: ThemeService
	) {
		this.subscriptions.push(
			this.userService.tenantName.subscribe(() => {
				if (localStorage.getItem('lastUsedRoles') !== null) {
					this.lastUsedRoles = JSON.parse(localStorage.getItem('lastUsedRoles')!);
				}
			}),

			this.appService.currentService.subscribe((currentService) => (this.selectedService = currentService)),

			this.router.events
				.pipe(
					map(() => this.services.find((y) => y.route === this.router.url.split('/')[1])?.displayName),
					distinctUntilChanged()
				)
				.subscribe((x) => this.appService.setCurrentService(x))
		);

		themeService.checkDarkMode();
	}

	public async ngOnInit() {
		if (environment.stage === 'dev') {
			this.canEditEndpoint = true;
		}

		// Bootstrap the userService here
		await this.userService.initialize();
		this.isLoggedIn = await this.userService.isLoggedIn();
		if (this.isLoggedIn) {
			this.userInfo = await this.userService.getUserInfo();
		}

		const crn = await this.userService.getCRN();
		this.checkAssumedRole(crn);
		this.loadAssumableRoles(crn);
		this.loadServicePolicies();
		// await this.searchCustomers('');

		this.userService.sessionToken.subscribe((token) => {
			if (token) {
				const tokenDec = new JwtHelperService().decodeToken(token);
				const tokenExpiry = tokenDec.exp;
				let currentDate = Math.floor(Date.now() / 1000);
				this.dateDiff = tokenExpiry - currentDate;

				const tokenTimer = setInterval(async () => {
					currentDate = Math.floor(Date.now() / 1000);
					this.dateDiff = tokenExpiry - currentDate;

					if (this.dateDiff === 300) {
						this.notificationBarService.notify('info', 'Info', ' Your session will expire in 5 minutes.');
					}
					if (this.dateDiff === 60) {
						this.notificationBarService.notify('info', 'Info', ' Your session will expire in 1 minute.');
					}
					if (this.dateDiff === 0) {
						clearInterval(tokenTimer);
						this.userService.leaveRole();

						const message = 'Your session has expired. You were logged out. Do you want to reassume the role?';
						const dialogRef = this.dialog.open(ReassumableRoleWildcardDialogComponent, {
							width: '600px',
							data: { message: message },
						});
						dialogRef.afterClosed().subscribe((result) => {
							if (result) {
								this.assumeRoleSplit(this.lastUsedRoles[0].tenant, this.lastUsedRoles[0].roles[0]);
							} else {
								return;
							}
						});
					}

					if (this.userService.getSession() === undefined) {
						clearInterval(tokenTimer);
						this.notificationBarService.notify('info', 'Info', 'You have left the session.\nYou were logged out.');
					}
				}, 1000);
			}
		});
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((x) => x.unsubscribe());
	}

	async loadServicePolicies() {
		try {
			const allAvailableServices = await this.serviceRegistryService.getAvailableServices();
			const serviceArray: ServiceType[] = [];
			allAvailableServices
				.filter((service: any) => service.route)
				.forEach((service) =>
					serviceArray.push({
						name: service.serviceName,
						displayName: service.route == 'coming-soon' ? service.displayName + ' (coming soon)' : service.displayName,
						route: service.route,
					})
				);

			serviceArray.sort((a, b) => a.displayName.localeCompare(b.displayName));

			if (!this.selectedService) {
				this.appService.setCurrentService(serviceArray.find((y) => y.route === this.router.url.split('/')[1])?.displayName);
			}

			this.services.push(...serviceArray);
		} catch (e) {
			console.log(e);
		}
	}

	public login() {
		this.keycloak.login();
	}

	public logout() {
		let redirectPath: string = '';
		if (window.location.hostname === 'localhost') {
			redirectPath = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/login`;
		} else {
			redirectPath = `${window.location.protocol}//${window.location.hostname}/login`;
		}
		this.keycloak.logout(redirectPath);
	}

	public toggleNav(): void {
		this.sidenavOpen = !this.sidenavOpen;
	}

	public openService(service: any): void {
		if (service.route != 'coming-soon') {
			// instead of not doing anything we should navigate to some coming soon component!?
			if (service.isGeneric) {
				this.router.navigate(['gen', service.route]);
			} else if (!!service.external) {
				window.open(service.external, '_blank');
			} else if (!service.isGeneric) {
				this.router.navigate([service.route]);
				this.setValuesOnLocalStorage(service);
			}
		}
	}

	private setValuesOnLocalStorage(service: any) {
		if (service.name !== 'Dashboard') {
			if (localStorage.getItem('recentlyUsedServices') === null) {
				this.retrieveServices = [];
			} else {
				this.retrieveServices = JSON.parse(localStorage.getItem('recentlyUsedServices')!);
			}

			let checkExists: boolean = false;
			for (let i = 0; i < this.retrieveServices.length; i++) {
				if (service.name === this.retrieveServices[i].name && service.displayName === this.retrieveServices[i].displayName) {
					this.retrieveServices.push(this.retrieveServices.splice(i, 1)[0]);
					checkExists = true;
				}
			}
			if (!checkExists) {
				this.retrieveServices.push(service);
			}

			localStorage.setItem('recentlyUsedServices', JSON.stringify(this.retrieveServices));
		}
	}

	async loadAssumableRoles(crn: string) {
		const res = await this.roleService.getAssumableAndWildcardRoles(crn);
		this.assumableRoles = res.assumableRoles;
		this.wildcardRoles = res.wildcardRoles;
	}

	public openAssumableRolesWildcardDialog() {
		try {
			this.dialog.open(AssumableRoleWildcardDialogComponent, { minWidth: '500px' });
		} catch (e) {
			console.log(e);
		}
	}

	async searchCustomers(customer: string) {
		try {
			this.customers = await this.customerdbService.searchCustomer(customer);
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
	}

	public assumeRole(crn: string) {
		this.userService.assumeRole(crn);
	}

	public assumeRoleSplit(tenant: string, role: string) {
		this.userService.assumeRole(`crn:${tenant}::iam:${role}`);
	}

	public checkAssumedRole(crn: string) {
		const currentRole = localStorage.getItem('currentRole');
		if (currentRole != null && currentRole != crn) {
			this.userService.assumeRole(currentRole);
		}
	}

	public removeLastUsedRole(tenant: string, role: string) {
		this.userService.removeLastUsedRoleFromLocalStorage(tenant, role);
		this.lastUsedRoles = JSON.parse(localStorage.getItem('lastUsedRoles')!);
	}

	public profile() {
		this.router.navigate(['account-management']);
	}

	public copyIdToClipboard() {
		this.clipboard.copy(this.userService.currentTenant!);
		this.notificationBarService.notify('info', undefined, `"${this.userService.currentTenant!}" was copied to the clipboard`);
	}

	public backendEndpointEditor() {
		try {
			this.dialog.open(OverwriteBackendEndpointDialogComponent, {
				data: {},
				autoFocus: false,
				minWidth: '600px',
			});
		} catch (e) {
			console.log(e);
		}
	}
}
