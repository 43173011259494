import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';

// async function delay(ms: number): Promise<void> {
// 	return new Promise((resolve) => setTimeout(resolve, ms));
// }

@Injectable({
	providedIn: 'root',
})
export class LoadingIndicatorService {
	private spinnerTopRef: OverlayRef;

	constructor(private overlay: Overlay) {
		this.spinnerTopRef = this.cdkSpinnerCreate();
	}

	private cdkSpinnerCreate(): OverlayRef {
		return this.overlay.create({
			hasBackdrop: true,
			backdropClass: 'dark-backdrop',
			positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
		});
	}

	public showSpinner(): void {
		this.spinnerTopRef.attach(new ComponentPortal(MatSpinner));
	}

	public stopSpinner(): void {
		this.spinnerTopRef.detach();
	}
}
