import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface TexteditorOptions {
	name: string;
	value?: string;
	json?: boolean;
	label?: string;
	btnText?: string;
}

@Component({
	selector: 'app-texteditor-dialog',
	templateUrl: './texteditor-dialog.component.html',
	styleUrls: ['./texteditor-dialog.component.scss'],
})
export class TexteditorDialogComponent {
	public name: string = 'Text';
	public value: string = '';
	public json: boolean | undefined;

	constructor(
		public dialogRef: MatDialogRef<TexteditorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TexteditorOptions
	) {
		this.name = data.name;
		if (data.value) {
			this.value = data.value;
			this.json = data.json;
		}
	}

	@ViewChild('autosize') autosize!: CdkTextareaAutosize;

	public update(text?: string) {
		this.dialogRef.close(this.data.json ? JSON.parse(text!) : text);
	}

	public cancel() {
		this.dialogRef.close();
	}
}
