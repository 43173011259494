import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
	selector: 'app-state-cell-renderer',
	templateUrl: './state-cell-renderer.component.html',
	styleUrls: ['./state-cell-renderer.component.scss'],
})
export class StateCellRendererComponent implements AgRendererComponent {
	public state: string = '';
	public icon: string = '';

	constructor() {}

	refresh(): boolean {
		return false;
	}

	agInit(params: ICellRendererParams): void {
		this.state = params.value;

		switch (this.state) {
			case 'success':
				this.icon = 'check_circle';
				break;
			case 'failed':
				this.icon = 'error';
				break;
			case 'running':
			case 'pending':
				this.icon = 'loop';
				break;
			case 'manual':
				this.icon = 'build';
				break;
			case 'created':
				this.icon = 'schedule';
				break;
			case 'expired':
				this.icon = 'hourglass_bottom';
				break;
			case 'canceled':
				this.icon = 'cancel';
				break;
		}
	}
}
