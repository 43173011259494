<p class="h2">
	{{ title }}
</p>

<div>
	<p>{{ message }}</p>
	@if (items) {
		<div>
			<ul>
				@for (item of items; track item) {
					<li>{{ item }}</li>
				}
			</ul>
		</div>
	}
</div>

<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
	<button mat-raised-button (click)="cancel()">Cancel</button>
	<button mat-raised-button color="primary" (click)="delete()">Yes</button>
</div>
