<div fxLayout="row" style="height: 100%">
	<div class="main-sidebar background-primary py1" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px">
		<div class="icon-text">
			<button mat-icon-button (click)="drawer.toggle()">
				@if (!drawer.opened) {
					<mat-icon>menu</mat-icon>
				}
				@if (drawer.opened) {
					<mat-icon>close</mat-icon>
				}
			</button>
			<span>SERVICES</span>
		</div>
		<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px">
			@if (canEditEndpoint) {
				<div class="icon-text">
					<button mat-icon-button (click)="backendEndpointEditor()">
						<mat-icon>admin_panel_settings</mat-icon>
					</button>
					<span>LOCAL ENDPOINT EDITOR</span>
				</div>
			}
			<div class="icon-text">
				<a mat-icon-button href="https://docs.cancom.page/ccp/" target="_blank" rel="noopener">
					<span class="material-symbols-outlined">description</span>
				</a>
				<span>DOCS</span>
			</div>
			<div class="icon-text">
				<a mat-icon-button href="mailto:{{ supportEMail }}" target="_blank" rel="noopener">
					<mat-icon>mail_outline</mat-icon>
				</a>
				<span>CONTACT</span>
			</div>
		</div>
	</div>
	<mat-drawer-container class="main-drawer">
		<mat-drawer #drawer mode="over">
			<!-- Services -->
			<!-- <div class="h2 m2">SERVICES</div> -->
			<img class="toolbar-logo mt2 mx2" alt="CANCOM Logo" src="../assets/cancom-red.svg" />
			<mat-divider class="mx2"></mat-divider>
			<div class="service-list m2">
				@for (service of services; track service) {
					<div (click)="openService(service); drawer.close()" fxLayoutAlign="start center">
						@if (selectedService === service.displayName) {
							<mat-icon class="color-primary px1">arrow_forward</mat-icon>
						}
						<strong [ngClass]="{ 'color-primary': selectedService === service.displayName }">
							{{ service.displayName }}
						</strong>
					</div>
				}
			</div>
		</mat-drawer>
		<mat-drawer-content fxLayout="column" width="100%">
			<mat-toolbar class="main-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
					<img class="toolbar-logo" alt="CANCOM Logo" src="../assets/cancom-red.svg" routerLink="/dashboard" />
					@if (stage === 'dev' || stage === 'staging') {
						<mat-chip disableRipple>
							{{ stage.toUpperCase() }}
						</mat-chip>
					}
				</div>
				<div fxLayout="row" fxLayoutGap="16px">
					<!-- Tenant Assumed Timeout -->
					@if (dateDiff === 0) {
						<div mat-button class="timeout-alert px2">ATTENTION! Role assumption expired</div>
					}
					<!-- Tenant -->
					@if (isLoggedIn) {
						@if ((userService.principalName | async) === (userService.userName | async)) {
							<button mat-button class="dark-button px2" [matMenuTriggerFor]="tenants">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									{{ userService.tenantName | async }}
									<mat-icon>keyboard_arrow_down</mat-icon>
								</span>
							</button>
						}
						<!-- Tenant Assumed -->
						@if ((userService.principalName | async) !== (userService.userName | async)) {
							<button mat-button class="dark-button pr2 pl0" [matMenuTriggerFor]="tenants">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									@if (dateDiff) {
										<button mat-button class="countdown-button px2">
											<countdown [config]="{ leftTime: dateDiff, format: 'mm:ss' }"></countdown>
										</button>
									}
									{{ (userService.tenantName | async) + ' - ' + (userService.principalName | async) }}
									<mat-icon>keyboard_arrow_down</mat-icon>
								</span>
							</button>
						}

						<!-- Tenant Menu -->
						<mat-menu #tenants="matMenu" xPosition="before" class="ccp-menu">
							<!-- Role assumed - menu for leave -->
							@if (userService.getSession() !== undefined) {
								<button mat-menu-item (click)="userService.leaveRole()">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>exit_to_app</mat-icon>
										<span>Leave role</span>
									</span>
								</button>
								<mat-divider></mat-divider>
							}
							<ng-container class="p2" (click)="$event.stopPropagation()">
								<!-- WILDCARD -->
								@if (wildcardRoles.length > 0) {
									<button mat-menu-item (click)="openAssumableRolesWildcardDialog()">
										<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
											<mat-icon>manage_accounts</mat-icon>
											<span>Change Context</span>
										</span>
									</button>
									<mat-divider></mat-divider>
								}
								<!-- LAST USED ROLES -->
								@for (obj of lastUsedRoles; track obj) {
									<button mat-menu-item [matMenuTriggerFor]="change">
										<span style="white-space: nowrap !important">{{ obj.displayName || obj.tenant }}</span>
									</button>
									<mat-menu #change="matMenu" class="fullwidth-menu" xPosition="before">
										@for (role of obj.roles; track role) {
											<div fxLayout="row" fxLayoutAlign="start center">
												<button mat-menu-item (click)="assumeRoleSplit(obj.tenant, role)">
													{{ role }}
												</button>
												<button mat-icon-button class="mr1" (click)="removeLastUsedRole(obj.tenant, role)">
													<mat-icon>clear</mat-icon>
												</button>
											</div>
										}
									</mat-menu>
								}
								@if (lastUsedRoles.length > 0) {
									<mat-divider></mat-divider>
								}
								<!-- ASSUMABLE ROLES NESTED MENU -->
								<div [style]="'max-height: 50vh'">
									@for (assumableRole of assumableRoles; track assumableRole) {
										<button mat-menu-item [matMenuTriggerFor]="roles">
											{{ assumableRole.displayName }}
										</button>
										<mat-menu #roles="matMenu" xPosition="before">
											@for (role of assumableRole.roles; track role) {
												<button mat-menu-item (click)="assumeRoleSplit(assumableRole.tenant, role)">
													{{ role }}
												</button>
											}
										</mat-menu>
									}
								</div>
							</ng-container>
						</mat-menu>
						<!-- Account -->
						<button mat-button [matMenuTriggerFor]="menu" class="round-button">
							<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
								{{ userService.userName | async }}
								<mat-icon>keyboard_arrow_down</mat-icon>
							</span>
						</button>
						<!-- Account Menu -->
						<mat-menu #menu="matMenu" xPosition="before" class="fullwidth-menu">
							<div class="m2" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
								<div fxLayout="column">
									<span>Current Tenant ID:</span>
									<span style="font-weight: lighter">{{ userService.currentTenant }}</span>
								</div>
								<button
									mat-icon-button
									(click)="copyIdToClipboard()"
									matTooltip="Copy Tenant ID to Clipboard"
									disableRipple
								>
									<mat-icon style="font-size: 20px">content_copy</mat-icon>
								</button>
							</div>
							<mat-divider></mat-divider>
							<button mat-menu-item (click)="profile()">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									<mat-icon>account_circle</mat-icon>
									<span>My Account</span>
								</span>
							</button>
							<button mat-menu-item (click)="logout()">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									<mat-icon>logout</mat-icon>
									<span>Logout</span>
								</span>
							</button>
							<button mat-menu-item (click)="themeService.toggleDarkTheme()">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									@if (!themeService.darkMode) {
										<mat-icon>dark_mode</mat-icon>
									}
									@if (themeService.darkMode) {
										<mat-icon>light_mode</mat-icon>
									}
									<span>Toggle {{ themeService.darkMode ? 'light' : 'dark' }} mode</span>
								</span>
							</button>
						</mat-menu>
					}
				</div>
			</mat-toolbar>

			<div class="sidenav-container">
				<router-outlet></router-outlet>
			</div>

			<footer fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
				<span class="copyright">© CANCOM {{ currentYear }}</span>
				<div class="links" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="2px">
					<div fxLayoutGap="16px">
						<a href="https://www.cancom.com" target="_blank" rel="noopener">
							<span>CANCOM Group</span>
						</a>
						<a href="mailto:{{ supportEMail }}" target="_blank" rel="noopener">
							<span>Contact</span>
						</a>
						<a href="https://www.cancom.com/imprint/" target="_blank" rel="noopener">
							<span>Imprint</span>
						</a>
					</div>
					<div fxLayoutGap="16px">
						<a href="https://de.cancom.com/privacy-protection/" target="_blank" rel="noopener">
							<span>Privacy Protection</span>
						</a>
						<a href="https://docs.cancom.page/ccp/" target="_blank" rel="noopener">
							<span>Documentation</span>
						</a>
					</div>
				</div>
			</footer>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
