import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * @deprecated Please use {@link ConfirmationBoxComponent} instead.
 *
 * {@link ConfirmationBoxComponent} is a drop-in replacement. This component will
 * be removed eventually.
 */
@Component({
	selector: 'app-delete-admonition-box',
	templateUrl: './delete-admonition-box.component.html',
	styleUrls: ['./delete-admonition-box.component.scss'],
})
export class DeleteAdmonitionBoxComponent {
	public title: string;
	public message: string;
	public items: string[] = [];

	constructor(
		public dialogRef: MatDialogRef<DeleteAdmonitionBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DeleteAdmonitionBoxComponent
	) {
		this.title = data.title;
		this.message = data.message;
		this.items = data.items;
	}

	delete(): void {
		this.dialogRef.close(true);
	}

	cancel(): void {
		this.dialogRef.close(false);
	}
}

export class ConfirmDialogModel {
	constructor(
		public title: string,
		public message: string
	) {}
}
